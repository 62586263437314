.container-tag {
  padding: 10px 15px;
  width: 100%;
  border-radius: 23px;
  border: solid 1px #e3e8f0;
  background-color: #f0f2f7;
  -webkit-transition: 0.5s; /* Safari */
  transition: 0.5s;
}

.items {
  display: inline-block;
  padding: 2px;
  border: 1px solid blue;
  font-family: Helvetica, sans-serif;
  border-radius: 5px;
  margin: 5px 5px 0 0;
  cursor: "pointer";
}

.input {
  outline: none;
  border: none;
  background: transparent;
}

.container-tag:focus-within {
  outline: none;
  box-shadow: 0px 8px 10px #e3e8f0;
}
