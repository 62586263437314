.input-text {
    padding: 10px 15px;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #e3e8f0;
    background-color: #f0f2f7;
    -webkit-transition: .5s; /* Safari */
    transition: .5s;
    outline: none;
}

.input-text:focus, .input-text:active {
    outline: none;
    box-shadow: 0px 8px 10px #e3e8f0;
}