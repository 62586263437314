/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #08509f #f0f2f7;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f0f2f7;
}

*::-webkit-scrollbar-thumb {
  background-color: #08509f;
  border-radius: 9px;
  border: 0px solid #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
